import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
       
        <a
          className="App-link"
          href="https://blogs.neelamegam.in/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Life is beautiful!
        </a>
      </header>
    </div>
  );
}

export default App;
